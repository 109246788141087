const base = {
  name: 'Documentation',
  url: 'https://gitlab.thalesdigital.io/thales-digital-platform/documentation/',
};

const config = {
  siteTitle: `${base.name}`,
  siteTitlePostfix: ` - ${base.name}`,
  siteDescription: `${base.name}`,
  siteLanguage: 'en',
  headerTitle: `${base.name}`,
  siteUrl: base.url,
};

module.exports = config;
