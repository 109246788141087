import React from 'react';
import { css } from '@emotion/core';

const PortalLink = () => {
  return (
    <a
      href={'https://thalesgroup.sharepoint.com/sites/TrustNest-Portal/'}
      className="tpz-btn tpz-btn-primary"
      css={css`
        color: white !important;
      `}
      alt="trustnest"
      target="_blank"
      rel="noopener noreferrer"
    >
      Go to TrustNest Portal
    </a>
  );
};

export default PortalLink;
